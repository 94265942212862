import axios from 'axios';
import { CalendarEventData } from '../global/CalendarEventData';

const localApi : Boolean = false
const url = localApi? 'http://localhost:4000/' : 'https://jcjyrahboj.execute-api.eu-west-2.amazonaws.com/Prod/events'

type CalendarApiResponse = {
    "Events" : CalendarEventData[],
    "Message" : string
}

export class AppService {
    public async getEvents(maxNumberOfEvents: number): Promise<CalendarEventData[]> {
        const params = new URLSearchParams([
            ['maxNumberOfEvents', maxNumberOfEvents.toString()],
            ['returnRaw', '']
        ]);

        const response = await axios.get<CalendarApiResponse>(
            url, 
            {params}
        );
        
        return response.data.Events
    }
}