import React from 'react';
import './EventList.css'
import CalendarEvent from '../CalendarEvent/CalendarEvent';
import { CalendarEventData } from '../../global/CalendarEventData';

function EventList(props: CalendarEventData[]){
    return <div className='eventlist'>
        {props.map(calendarEvent => 
            <div key={calendarEvent.Start.toString()} >
              {CalendarEvent(calendarEvent)}
            </div>)} 
    </div>
}

export default EventList