import React from "react"
import './Location.css'

function Location({ location }: { location: string }){

    try {
        new URL(location);
        return <div className='location'><a href={location} target="_blank" rel="noreferrer">{location}</a></div>
      } catch (TypeError) {
        return <div className='location'>{location}</div>
      }
}

export default Location