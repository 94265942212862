import React from 'react';
import {DateTime} from "ts-luxon";

interface TimeSpanProps {
    Start? : string
    End? : string
    Highlight : boolean
}

function TimeSpan(props: TimeSpanProps){

    const start = props.Start == null? null : DateTime.fromISO(props.Start, {zone: 'system'})
    const end = props.End == null? null : DateTime.fromISO(props.End, {zone: 'system'})
    const classString = props.Highlight? 'time-span event-highlighted' : 'time-span'

    if (start != null && end == null) {
        return <div className={classString}>{start.toLocaleString(DateTime.DATETIME_MED)}</div>
    }

    if (start != null && end != null) {
        return <div className={classString}>{start.toLocaleString({
            weekday:'short', day:'numeric', month:'short', hour:'numeric', minute:'numeric', hour12:false
        })}–{end.toLocaleString(DateTime.TIME_24_SIMPLE)}</div>
    }

    // All other states are invalid and should result in an empty element
    return <div className={classString}/>

}

export default TimeSpan