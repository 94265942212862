import React, { useEffect } from 'react';
import {useState} from 'react'
import {Settings} from "ts-luxon";
import EventList from './components/EventList/EventList'
import { CalendarEventData } from './global/CalendarEventData';
import './App.css';
import { AppService } from './services/app.services';

function App() {

  const [calendarEvents, setCalendarEvents] = useState<CalendarEventData[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  useEffect(() => {
    document.title = "Air Adam Events"
    
    var numberOfEvents = getMaxEventsCountConfig(); 
    
    const appService = new AppService()
    appService.getEvents(numberOfEvents)
    .then(x => {
      console.log("App.tsx - API request successful", x)
      // Set location on all events where none specified to usual URL - API doesn't do this for specific reasons.
      x.forEach(y => {if (!y.Location){y.Location="https://twitch.tv/airadam13"} })
      setCalendarEvents(x)
      setIsLoading(false)
      })
    .catch( x => {
      console.log("App.tsx - API request failure", x)
    })
  },[])

  return (
    <div className="App">
      <header className="App-header">
          <h1>Air Adam Events</h1>   
      </header>
      {isLoading? <img src={process.env.PUBLIC_URL + "/pulse.gif"} alt="Pulsing wait animation - information being retrieved." /> : EventList(calendarEvents)} 
      <footer><div className='App-time-zone'>All times shown for time zone : {Settings.defaultZone.name}</div></footer>
    </div>
  );

  function getMaxEventsCountConfig() {
    var numberOfEvents = 5;
    const queryParameters = new URLSearchParams(window.location.search);
    const eventsParamValue = queryParameters.get("events");

    if (eventsParamValue != null) {
      const parsedEventsParamValue = Number.parseInt(eventsParamValue, 10);
      if (!Number.isNaN(parsedEventsParamValue)) {
        numberOfEvents = parsedEventsParamValue;
      }
    }

    return numberOfEvents;
  }
}

export default App;
