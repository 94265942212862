import React from 'react';
import { CalendarEventData } from '../../global/CalendarEventData';
import TimeSpan from '../TimeSpan/TimeSpan'
import Location from '../Location/Location'
import './CalendarEvent.css';
import pasticker from './sticker.jpg'

function CalendarEvent(props: CalendarEventData){
    const timeSpanData = {Start: props.Start, End:props.End, Highlight:props.Highlight}
    
    return  <div className='calendar-event'>
                {TimeSpan(timeSpanData)}
                {props.Location && Location({location: props.Location})} 
                <div className='calendar-event-title'>{props.Title}</div>  
                {props.Explicit === true? <div className='calendar-event-explicit'><img src={pasticker} width="50" alt="Parental advisory sticker"></img></div> : <div className='event-explicit-flag'/>}            
            </div>
}

export default CalendarEvent